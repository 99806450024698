import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {map, Observable} from 'rxjs';
import {mergeMap, startWith} from 'rxjs/operators';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {HasIdName} from '../model';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-filter-select[label][findFn][selected]',
  standalone: true,
  templateUrl: 'filter-select.component.html',
  imports: [CommonModule, ReactiveFormsModule, MatAutocompleteModule, MatInputModule, MatChipsModule, MatIconModule]
})
export class FilterSelectComponent implements OnInit {
  @Input() label!: string;
  @Input() findFn!: (q: string) => Observable<HasIdName[]>;
  @Input() selected: HasIdName[] = [];
  @Output() selectedChange = new EventEmitter<HasIdName[]>();
  control = new FormControl('');
  options!: Observable<HasIdName[]>;
  separatorKeysCodes = [ENTER, COMMA];

  ngOnInit() {
    this.options = this.control.valueChanges.pipe(
      startWith(''),
      mergeMap(value => this.findFn(value || '')),
      map(result => {
        const ids = this.selected.map(({id}) => id);
        return result.filter(e => ids.indexOf(e.id) == -1)
      })
    );
  }

  add(option: HasIdName) {
    console.log('will add', option)
    this.selected = [...this.selected, option]
      .sort((a, b) => a.name.localeCompare(b.name))
    console.log('sub change', this.selected)
    this.selectedChange.emit(this.selected);
  }

  remove(option: HasIdName) {
    const index = this.selected.findIndex(({id}) => id == option.id);
    if (index >= 0) {
      this.selected.splice(index, 1);
      this.selectedChange.emit(this.selected);
    }
  }

  clearInput(input: HTMLInputElement) {
    input.value = '';
    this.control.setValue(null);
  }
}

