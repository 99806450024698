import {HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

export const authenticationInterceptor: HttpInterceptorFn =
  (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AppAuthService);
    return authService.user$.pipe(
      mergeMap(user => next(
        user
          ? req.clone({headers: req.headers.set('Authorization', `Bearer ${user.token}`)})
          : req
      )));
  };
