<div class="form" style="margin-bottom: 1em">
  <app-filter-select (selectedChange)="tags=$event; search()" [(selected)]="tags"
                     [findFn]="findTagByName" label="contains Tag"></app-filter-select>
</div>

<div class="split">
  <app-map (selectedChange)="selectTag($event)" [outlines$]="outlinesResult$" [tag]="tag"
           [tags$]="tagsResult$"></app-map>
  <div *ngIf="list.length; else noData">
    <div *ngFor="let i of list" class="row">
      <img *ngIf="i.image; else noImage" [src]="i.image" width="100">
      <ng-template #noImage>
        <div style="background: #eee; height:60px; text-align: center">
          <mat-icon style="margin-top:20px; ">cancel_presentation</mat-icon>
        </div>
      </ng-template>
      <div>
        {{ i.date | date:'dd.MM.yyyy' }}
      </div>
      <div>
        {{ i.title }}
      </div>
    </div>
  </div>
  <ng-template #noData>
    <div>no results found</div>
  </ng-template>
</div>
