import {Component, NgZone, OnInit} from '@angular/core';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {ServerVersionService} from './server-version.service';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'version-reload',
  template: '',
  standalone: true
})
export class VersionReloadComponent implements OnInit {
  version: { server?: string, client: string } = {client: ''};
  alert?: ActiveToast<any>;

  constructor(private server: ServerVersionService, private zone: NgZone, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.server.subscribe(async version => {
      this.version = version;
      if (version.server && !version.server.endsWith('-dirty') && !version.client.endsWith('-dirty') &&
        version.server != version.client && !this.alert) {
        this.alert = this.toastr.warning(
          'please click <a href="#">here</a> to reload',
          'Attention: New App-Version',
          {disableTimeOut: true});
        await firstValueFrom(this.alert.onTap);
        this.alert = undefined;
        this.zone.runOutsideAngular(() => location.reload());
      }
    });
  }
}
