import {HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import {inject} from '@angular/core';
import {ServerVersionService} from '../../server-version.service';
import {tap} from 'rxjs/operators';

export const versionInterceptor: HttpInterceptorFn = (req, next) => {
  const version = inject(ServerVersionService);
  return next(req).pipe(tap((res) => {
    if (res instanceof HttpResponse) {
      version.update(res.headers.get('x-application-version') || undefined);
    }
  }));
};
