<mat-form-field>
  <mat-label>URL</mat-label>
  <input [(ngModel)]="url" matInput type="text">
</mat-form-field>

<div>
  <a (click)="parse()" mat-raised-button>go!</a>
</div>

<pre>{{ result | json }}</pre>
