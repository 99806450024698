import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {finalize} from 'rxjs/operators';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loader = inject(LoadingBarService).useRef();
  loader.start();
  return next(req).pipe(finalize(() => loader.complete()));
};
