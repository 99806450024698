import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AppAuthService} from './app-auth.service';
import {map} from 'rxjs/operators';

export const authenticatedGuard = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AppAuthService);
  return authService.user$.pipe(
    map(async user => {
      if (user == null) {
        if (state.url != '/' && !state.url.startsWith('/home') && !localStorage.getItem('returnUrl')) {
          localStorage.setItem('returnUrl', state.url);
        }
        await router.navigate(['/login']);
        return false;
      }
      return true;
    }));
};
