<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav [class.mat-elevation-z4]="true">
    <mat-nav-list *ngIf="company; else noCompany">

      <span matListItemLine style="margin:5px">Company</span>
      <mat-list-item [class.activated]="isDashboard" [routerLink]="['/company',company.id,'dashboard']">
        Dashboard
      </mat-list-item>

      <mat-list-item [routerLink]="['/company',company.id,'document']" routerLinkActive="activated">
        General Documents
      </mat-list-item>

      <mat-list-item [routerLink]="['/company',company.id,'supplier']" routerLinkActive="activated">
        My Suppliers
      </mat-list-item>

      <mat-list-item [routerLink]="['/company',company.id,'report']" routerLinkActive="activated">
        My Reports
      </mat-list-item>

      <mat-divider></mat-divider>

      <span matListItemLine style="margin:5px">Sites</span>
      <ng-container *ngFor="let s of company.site">
        <mat-list-item [style.font-weight]="s.id == site?.id ? 'bold' : 'inherit'" disabled>
          <div style="display: flex; gap:10px; align-items: center">
            <app-site-icon [siteType]="s.siteType"></app-site-icon>
            {{ s.name }}
          </div>
        </mat-list-item>
        <mat-list-item [routerLink]="['/company',company.id,'site',s.id,'document']"
                       routerLinkActive="activated" style="margin-left:20px;">
          Site Documents
        </mat-list-item>
        <mat-list-item [routerLink]="['/company',company.id,'site',s.id,'chain']" routerLinkActive="activated"
                       style="margin-left:20px;">
          Supply Chains
        </mat-list-item>
      </ng-container>
    </mat-nav-list>
    <ng-template #noCompany>
      <mat-nav-list>
        <a class="back">Main</a>
        <mat-list-item [routerLink]="['dashboard']" routerLinkActive="activated">
          Dashboard
        </mat-list-item>
        <a class="back">Select Company</a>
        <mat-list-item *ngFor="let c of companies" [routerLink]="['/company',c.id,'dashboard']">
          <div style="display: flex; gap:10px; align-items: center">
            <mat-icon style="opacity: 0.7;">apartment</mat-icon>
            {{ c.name }}
          </div>
        </mat-list-item>
      </mat-nav-list>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <main [class.wide]="wide">
      <ng-content></ng-content>
    </main>
  </mat-sidenav-content>

</mat-sidenav-container>
