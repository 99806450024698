<mat-form-field style="width: 100%;">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row (removed)="remove(option)" *ngFor="let option of selected">
      {{option.name}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input #input (matChipInputTokenEnd)="clearInput(input)"
         [formControl]="control" [matAutocomplete]="auto"
         [matChipInputFor]="chipGrid"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"/>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="add($event.option.value); clearInput(input);"
                    autoActiveFirstOption>
    <mat-option *ngFor="let option of options | async" [value]="option">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
