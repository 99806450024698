import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AppAuthService} from './app-auth.service';
import {tap} from 'rxjs/operators';

export const notAuthenticatedGuard = () => {
  const router = inject(Router);
  let user$ = inject(AppAuthService).user$;
  return user$.pipe(
      tap(user => user == null ? true : router.navigate(['/']))
  );
};
