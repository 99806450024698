import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private router: Router, private zone: NgZone) {
  }

  handleError(error: any) {
    while (error.rejection) {
      error = error.rejection;
    }
    console.error(error);
    console.error('msg', error.message);

    if (error.message.startsWith('Failed to fetch dynamically imported module')) {
      console.error('trigger reload!');
      this.zone.runOutsideAngular(() => location.reload());
    }

    if (error instanceof HttpErrorResponse) {
      console.error(error.error);
    }
    const toastr = this.injector.get(ToastrService);
    const title = 'Error on page ' + this.router.url;
    let message = error.message;
    if (error instanceof HttpErrorResponse) {
      message += `<div class="pre">${JSON.stringify(error.error, undefined, 2)}</div>`;
    }
    this.zone.run(() => toastr.error(message, title, {disableTimeOut: true}));
  }
}
