import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MatChipListbox, MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import * as mapboxgl from 'mapbox-gl';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {HasIdName, NewsEventGeo} from '../model';
import {ApiService} from '../common/service';

function mapColor(count: number, avg: number) {
  if (avg < -9) return 'rgba(255,0,0,1.0)';
  if (avg < -8) return 'rgba(255,12,12,0.85)';
  if (avg < -7) return 'rgba(255,55,55,0.8)';
  if (avg < -6) return 'rgba(255,48,48,0.75)';
  if (avg < -4) return 'rgba(255,65,65,0.7)';
  if (avg < -2) return 'rgba(255,112,112,0.65)';
  if (avg < 0) return 'rgba(255,150,150,0.6)';
  return 'rgba(170,170,170,0.94)';
}

function mapOpacity(count: number, avg: number) {
  // if (count > 0.1) return 0.4;
  // if (count > 0.05) return 0.2;
  // return 0.1;
  return 0.5;
}

export type MapMarker = {
  type: 'buyer' | 'supplier' | 'home'
  name: string
  lng: number
  lat: number
  id: string
}

@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: 'map.component.html',
  styles: ['.match-parent {  width: 100%; height: 600px}'],
  imports: [CommonModule, MatChipsModule, MatIconModule]
})
export class MapComponent implements OnInit {
  @Input() outlines$: Observable<NewsEventGeo[]> = of([]);
  outlines: NewsEventGeo[] = [];
  @Input() markers$: Observable<MapMarker[]> = of([]);
  markers: mapboxgl.Marker[] = [];
  @Input() tags$: Observable<HasIdName[]> = of([]);
  tags: HasIdName[] = [];
  @Input() tag: string | undefined;
  @Output() selectedChange = new EventEmitter<HasIdName | undefined>();
  map!: mapboxgl.Map;
  @ViewChild('mapContainer', {static: true}) mapContainer!: ElementRef;
  @ViewChild('chipListbox', {static: true}) chipListbox!: MatChipListbox;

  constructor(private api: ApiService, private router: Router) {
  }

  ngOnInit() {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: this.mapContainer.nativeElement,
      style: 'mapbox://styles/mapbox/light-v11',
      zoom: 3,
      center: [-51.9253, -14.2350]
    });
    this.map.on('load', () => {
      this.tags$.subscribe((tags) => this.tags = tags);
      this.markers$.subscribe((markers) => this.updateMarkers(markers)
      );
      this.outlines$.subscribe((outlines) => this.updateOutlines(outlines));
      this.map.resize();
    });
  }

  selectTag(selected: boolean, tag: HasIdName) {
    if (selected) {
      if (this.tag != tag.id) {
        this.selectedChange.emit(this.tags.find(({id}) => id == tag.id));
      }
    } else {
      this.tag = undefined;
      this.selectedChange.emit(undefined);
    }
  }

  private async loadLayer(id: string) {
    if (this.map.getSource('source-' + id)) {
      return of([]);
    }
    const json = await this.api.loadShapeShort(id);
    this.map.addSource('source-' + id, {'type': 'geojson', 'data': json});
    return json;
  }

  private updateMarkers(markers: MapMarker[]) {
    const createMarker = (type: 'buyer' | 'supplier' | 'home', name: string, lat: number, lng: number, id: string) => {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url(assets/icon_${type}.png)`;
      el.style.width = `32px`;
      el.style.height = `32px`;
      el.style.backgroundSize = '100%';
      el.style.cursor = 'pointer';
      el.title = type.slice(0, 1).toUpperCase() + type.slice(1) + ':' + name;
      el.addEventListener('click', () => this.router.navigate(['company', id]));
      return new mapboxgl.Marker(el).setLngLat({lng, lat}).addTo(this.map);
    };

    this.markers.forEach(marker => marker.remove());
    this.markers = markers.map(({type, lat, lng, name, id}) =>
      createMarker(type, name, lat, lng, id));
  }

  private updateOutlines(outlines: NewsEventGeo[]) {
    const layerName = (id: string) => `layer-${id}`;
    this.outlines.forEach(({id}) => {
      const name = layerName(id);
      if (this.map.getLayer(name)) {
        this.map.removeLayer(name);
      }
    });
    this.outlines = outlines;
    const total = outlines.reduce((total, {count}) => total + count, 0);
    outlines.forEach(async ({id, count, avg}) => {
      const json = await this.loadLayer(id);
      this.map.addLayer({
        'id': layerName(id),
        'type': 'fill',
        'source': 'source-' + id,
        'paint': {
          'fill-color': mapColor(count / total, avg),
          'fill-opacity': mapOpacity(count / total, avg)
        }
      });
    });
//   this.  map.loadImage(
//       'assets/stripes.png',
//       (err, image) => {
// // Throw an error if something goes wrong.
//         if (err) throw err;
// if (image === undefined) throw "no image";
//
// // Add the image to the map style.
//       this.  map.addImage('pattern', image);
//
//         this.loadLayer('BR-BO').subscribe(() => {
//           this.map.addLayer({
//             'id': 'pattern',
//             'type': 'fill',
//             'source': 'source-BR-MA',
//             'paint': {
//               'fill-pattern': 'pattern'
//             }
//           });
//         });
//       });
  }
}
