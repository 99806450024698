import {Component} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MapComponent} from './map.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {BehaviorSubject, of} from 'rxjs';
import {HasIdName, NewsEvent, NewsEventGeo} from '../model';
import {FilterSelectComponent} from './filter-select.component';

@Component({
  standalone: true,
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
  imports: [CommonModule, MatCardModule, MapComponent, FilterSelectComponent, MatIconModule]
})
export class DashboardComponent {
  tag: string | undefined;
  tags: HasIdName[] = [];
  list: NewsEvent[] = [];
  private tagsResult = new BehaviorSubject<HasIdName[]>([]);
  tagsResult$ = this.tagsResult.asObservable();
  private outlinesResult = new BehaviorSubject<NewsEventGeo[]>(
    [{id: 'DE-SN', avg: -9, count: 10}]);
  outlinesResult$ = this.outlinesResult.asObservable();

  findTagByName = (q: string) => of([]);

  async search() {
    // const tag = this.tag || undefined;
    // const tags = this.tags.map(({id}) => id).join(',') || undefined;
    // await this.router.navigate([], {replaceUrl: true, queryParams: {tag, tags, companies/*, industries*/}});
  }

  async selectTag(tag: HasIdName | undefined) {
    this.tag = tag?.id;
    await this.search();
  }
}
