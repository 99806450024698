<div #mapContainer class="map match-parent"></div>

<mat-chip-listbox #chipListbox>
  <mat-chip-option (selectionChange)="selectTag($event.selected, option)"
                   *ngFor="let option of tags"
                   [selected]="option.id == tag"
                   [value]="option.id">
    {{option.name}}
  </mat-chip-option>
</mat-chip-listbox>
